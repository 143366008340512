import { createApp } from 'vue';
import App from './App.vue';

import VueRouter from './router/router.js';
import VueStore from './store/store.js';
import VueSocket from './socket/socket.js';
import VueCookies from 'vue-cookies'

const app = createApp(App);

app.config.globalProperties.$socket = VueSocket;

app.use(VueRouter);
app.use(VueStore);
app.use(VueCookies, { expires: '365d'});
app.use(VueSocket);
app.mount('#app')
