import { createStore } from 'vuex';

// Create and export the Vuex store
export default createStore({
  state: {
    SiteType: "Tools", 
    SiteName: "Text in line converter", 
    SiteLongName: "Text in line converter", 
  },
});
