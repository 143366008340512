<template>
    <div class="page-header" style="padding-bottom: 20px">
        <div class="row align-items-center mw-100">
            <div class="col">
            <div class="mb-1">
                <ol class="breadcrumb" aria-label="breadcrumbs">
                <li class="breadcrumb-item"><a @click="GoHome()" href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">{{ this.$store.state.SiteType }}</a></li>
                <li class="breadcrumb-item" aria-current="page" style="font-weight:800"><a href="#">{{ this.$store.state.SiteName }}</a></li>
                </ol>
            </div>
            <h2 class="page-title">
                <span class="text-truncate">{{ this.$store.state.SiteLongName }}</span>
            </h2>
            </div>
            <div class="col-auto">
            <div class="btn-list">
                <a href="#" class="btn d-none d-md-inline-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                    <path d="M16 5l3 3" />
                </svg>
                Add to favourite
                </a>
                <a href="#" class="btn btn-primary">
                All favourites
                </a>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SiteHeader",
    methods: {
        GoHome() {
            this.$router.push("/")
        }
    }
}
</script>