import { createRouter, createWebHistory } from 'vue-router';

import Index from '../views/Index.vue';

import TextInLineConverter from '../views/TextInLineConverter.vue';
import SpacesRemover from '../views/SpacesRemover.vue';
import About from '../views/About.vue';
import DataProtection from '../views/DataProtection.vue';

// Create a new Vue Router instance
const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/tools/text-in-line-converter',
      name: 'TextInLineConverter',
      component: TextInLineConverter
    },
    {
      path: '/tools/spaces-remover',
      name: 'SpacesRemover',
      component: SpacesRemover
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/data-protection',
      name: 'DataProtection',
      component: DataProtection
    },
  ]
});

export default router;
