<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
import { RouterView } from 'vue-router'; 

export default {
  name: 'App',
  components: {
    RouterView
  },
};
</script>


<style>
:root {
  --tblr-font-sans-serif: 'Inter' !important;
}
body, html, :root {
  overflow: hidden !important;
}
body {
  overflow: scroll !important;
  background-color: rgba(211, 211, 211, 0.15) !important;
}
.page {
  padding: 15px;
}
</style>